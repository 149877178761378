import { createContext } from 'react';

import { Session, User } from '@supabase/supabase-js';

const stub = (): never => {
  throw new Error('AuthContext not initialized');
};

type AuthContextType = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: User | null;
  session: Session | null;

  signIn: (username: string, password: string) => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  signOut: () => Promise<void>;
  signUp: (email: string, password: string) => Promise<void>;

  refreshSession: () => Promise<{ error: Error | null }>;
};

export const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  session: null,

  signIn: stub,
  signInWithGoogle: stub,
  signOut: stub,
  signUp: stub,

  refreshSession: stub,
});

export const AuthConsumer = AuthContext.Consumer;
