import {
  blue,
  customBlue,
  customDarkGreen,
  customGreen,
  customLightBlue,
  customPurple,
  customRed,
  customYellow,
  green,
  indigo,
  lightBlue,
  purple,
} from './colors';

export const getPrimary = (preset: string) => {
  switch (preset.toLowerCase()) {
    case 'blue':
      return blue;
    case 'green':
      return green;
    case 'indigo':
      return indigo;
    case 'lightblue':
      return lightBlue;
    case 'purple':
    case '#9c27b0':
      return purple;
    case '#7c3aed':
      return customPurple;
    case '#3f51b5':
      return customBlue;
    case '#45dd82':
      return customGreen;
    case '#e95454':
      return customRed;
    case '#504b3a':
      return customDarkGreen;
    case '#54b3e9':
      return customLightBlue;
    case '#edb540':
      return customYellow;
    default:
      console.error(`Invalid color preset '${preset}' - defaulting to blue`);
      return blue;
  }
};
