import { useEffect, useRef } from 'react';

import { LUPA_ERROR_KEY } from '@lupa/utils/enums';

import { toast } from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';

// http://localhost:3002/notes?redirect-reason=auth_user_has_no_work_profile_but_has_remus_profile

export function useRemusLoginRedirectToast({ duration }: { duration: number }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const toastIdRef = useRef<string | null>(null);

  useEffect(() => {
    if (toastIdRef.current != null) {
      return;
    }

    const redirectReason = searchParams.get('redirect-reason');

    if (
      redirectReason ===
      LUPA_ERROR_KEY.AUTH_USER_HAS_NO_WORK_PROFILE_BUT_HAS_REMUS_PROFILE
    ) {
      const newToastId = toast.success(
        'You were redirected from the Lupa OS login page.\n\nPlease bookmark this page for future logins!',
        { duration, position: 'top-center', icon: '⚠️' },
      );
      toastIdRef.current = newToastId;

      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete('redirect-reason');
      setSearchParams(newSearchParams, { replace: true });
    }
  }, [searchParams, setSearchParams]);
}
