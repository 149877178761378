import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Session, SupabaseClient } from '@supabase/supabase-js';

import { AuthContext } from './AuthContext';

interface AuthProviderProps {
  supabaseProvider: () => Promise<SupabaseClient>;
  sessionUpdateCallback?: (session: Session | null) => void;
  children: ReactNode;
}

export const AuthProvider = ({
  supabaseProvider: getSupabase,
  sessionUpdateCallback,
  children,
}: AuthProviderProps) => {
  const [session, setSession] = useState<Session | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    sessionUpdateCallback?.(session);
  }, [session, sessionUpdateCallback]);

  useEffect(() => {
    const unsubscribeFnPromise = getSupabase().then(async (supabase) => {
      supabase.auth.getSession().then(({ data: { session } }) => {
        setSession(session);
        setIsInitialized(true);
      });

      const { data: authListener } = supabase.auth.onAuthStateChange(
        async (event, session) => {
          setSession(session);
        },
      );

      return () => {
        authListener?.subscription?.unsubscribe();
      };
    });

    return () => {
      unsubscribeFnPromise.then((unsubscribeFn) => unsubscribeFn());
    };
  }, []);

  const signOut = useCallback(async () => {
    const supabase = await getSupabase();
    const { error } = await supabase.auth.signOut();

    if (error) {
      throw error;
    }
  }, []);

  const signIn = useCallback(async (email: string, password: string) => {
    const supabase = await getSupabase();
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      throw error;
    }
  }, []);

  const signUp = useCallback(async (email: string, password: string) => {
    const supabase = await getSupabase();
    const { error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        emailRedirectTo: window.location.href,
      },
    });

    if (error) {
      throw error;
    }
  }, []);

  const signInWithGoogle = useCallback(async () => {
    const supabase = await getSupabase();
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: `${window.location.origin}`,
      },
    });

    if (error) {
      throw error;
    }
  }, []);

  const refreshSession = useCallback(async () => {
    const supabase = await getSupabase();
    return await supabase.auth.refreshSession();
  }, []);

  const value = useMemo(
    () => ({
      isAuthenticated: session?.access_token != null,
      isInitialized,
      session,
      user: session?.user ?? null,
      signIn,
      signUp,
      signInWithGoogle,
      signOut,
      refreshSession,
    }),
    [session, isInitialized],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
