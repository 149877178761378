import React, { ReactNode, useEffect } from 'react';

import { useAuth } from '../hooks/use-auth';

interface AuthGuardProps {
  children: ReactNode;
  loginPagePath: string;
}

export const AuthGuard: React.FC<AuthGuardProps> = ({
  children,
  loginPagePath,
}) => {
  const { isAuthenticated, isInitialized } = useAuth();

  useEffect(() => {
    const shouldRedirectToLogin = isInitialized && !isAuthenticated;

    if (shouldRedirectToLogin) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('returnTo', window.location.pathname);

      window.location.href = `${loginPagePath}?${searchParams}`;
    }
  }, [isAuthenticated, isInitialized]);

  if (!isInitialized || !isAuthenticated) {
    return null;
  }

  return children;
};
