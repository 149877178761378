import { getFrontendFeatureFlagValue } from '@lupa/ui/hooks/use-feature-flags';
import { TrpcRouterOutputs } from '@lupa/web/lib/trpc';
import { IS_DEV } from '@lupa/web/utils/environment-utils';

import { Session } from '@supabase/supabase-js';
import { QueryClient } from '@tanstack/react-query';

import { Socket } from 'socket.io-client';

const ENABLE_QUERIES_WHEN_OFFLINE = getFrontendFeatureFlagValue(
  'ENABLE_QUERIES_WHEN_OFFLINE',
);

interface GlobalSingleton {
  accessToken: Nullish<string>;
  userId: Nullish<string>;
  queryClient: QueryClient;
  isTestUser: boolean;
  currencyFormatter: Intl.NumberFormat;
  socket: Nullable<Socket>;
}

const trpcNetworkModeOverride = ENABLE_QUERIES_WHEN_OFFLINE
  ? { networkMode: 'always' as const }
  : {};

export const globalSingleton: GlobalSingleton = {
  accessToken: null,
  userId: null,
  isTestUser: IS_DEV,
  queryClient: new QueryClient({
    defaultOptions: {
      queries: {
        ...trpcNetworkModeOverride,
        staleTime: 3 * (60 * 1000), // 3 mins
      },
      mutations: {
        ...trpcNetworkModeOverride,
      },
    },
  }),
  currencyFormatter: new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 0,
  }),
  socket: null,
};

export const initializeGlobalSingleton = (
  data: TrpcRouterOutputs['booking']['getStore'],
) => {
  globalSingleton.isTestUser =
    IS_DEV || globalSingleton.userId === '3a9dfa6a-90b8-4dc1-a02a-bd929ec4b178';

  globalSingleton.currencyFormatter = new Intl.NumberFormat(data.locale, {
    style: 'currency',
    currency: data.currency_code,
    minimumFractionDigits: 0,
  });
};

export const updateGlobalSingletonWithSession = (session: Session | null) => {
  globalSingleton.accessToken = session?.access_token ?? null;
  globalSingleton.userId = session?.user.id ?? null;
};
