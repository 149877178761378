import isBrowser from '@lupa/ui/utils/is-browser';

import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import * as R from 'remeda';

const LOCALSTORAGE_KEY = 'lupaFrontendFeatureFlagOverrides';

const FLAGS = {
  SHOW_DEV_TOOLS: {
    description: 'Show dev tools, like the dev banner & react-query',
    default: false,
  },
  ENABLE_QUERIES_WHEN_OFFLINE: {
    description:
      "By default, trpc won't bother to queries if it detects you're offline, which can break offline work even against localhost",
    default: false,
  },
} as const;

type FrontendFeatureFlagKey = keyof typeof FLAGS;
type FrontendFeatureFlagState = Record<FrontendFeatureFlagKey, boolean>;

const frontendFeatureFlagOverridesAtom = atomWithStorage<
  Partial<FrontendFeatureFlagState>
>(LOCALSTORAGE_KEY, {}, undefined, { getOnInit: true });

export function getFrontendFeatureFlagValue(key: FrontendFeatureFlagKey) {
  const defaultValue = FLAGS[key].default;
  if (!isBrowser()) return defaultValue;

  const overrides = localStorage.getItem(LOCALSTORAGE_KEY);
  if (!overrides) return defaultValue;

  const parsedOverrides = JSON.parse(overrides);
  const overriddenValue = parsedOverrides[key];

  return overriddenValue ?? defaultValue;
}

export function useFrontendFeatureFlag(key: FrontendFeatureFlagKey) {
  const [overrides, setOverrides] = useAtom(frontendFeatureFlagOverridesAtom);

  const value = overrides[key] ?? FLAGS[key].default;
  const setOverride = (value: boolean | undefined) => {
    setOverrides((overrides) => ({ ...overrides, [key]: value }));
  };

  return {
    value,
    setOverride,
  };
}

export function useFrontendFeatureFlags() {
  const [overrides, setOverrides] = useAtom(frontendFeatureFlagOverridesAtom);

  const flags = R.mapValues(FLAGS, (flag, flagName) => ({
    ...flag,
    override: overrides[flagName],
    value: overrides[flagName] ?? flag.default,
  }));

  return {
    flags,
    setOverrides,
  };
}

export type FrontendFeatureFlagsType = ReturnType<
  typeof useFrontendFeatureFlags
>['flags'];
export type FrontendFeatureFlagsSetOverridesType = ReturnType<
  typeof useFrontendFeatureFlags
>['setOverrides'];
