import { isIgnorableSupabaseAuthError } from '@lupa/utils/supabaseAuthUtils';

import * as Sentry from '@sentry/react';

export function initSentryClient({
  dsn,
  backendUrl,
  environment,
}: {
  dsn: string;
  backendUrl: string;
  environment: 'development' | 'production';
}) {
  Sentry.init({
    dsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        // https://docs.sentry.io/platforms/javascript/session-replay/privacy/#privacy-configuration
        unmask: ['[data-sentry-no-mask]', '.sentry-no-redact'], // HTML attribute or CSS class to NOT redact sensitive data
        unblock: ['[data-sentry-no-block]', '.sentry-no-block'], // HTML attribute or CSS Class to NOT block entire sections (images, videos, maps, etc.)
        maskFn: (s) => '•'.repeat(s.length), // I like bullets for redaction instead of asterisks
      }),
      Sentry.captureConsoleIntegration({
        levels: ['error'],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', backendUrl],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment,

    beforeSend(event, hint) {
      if (isIgnorableSupabaseAuthError(hint.originalException)) {
        console.debug(
          'Not reporting Supabase auth error to Sentry - ignored.',
          hint.originalException,
        );
        return null;
      }

      return event;
    },
  });
}
