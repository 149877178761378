import { AuthError, isAuthError } from '@supabase/auth-js';

const AUTH_ERROR_CODES_TO_IGNORE: Array<AuthError['code']> = [
  'session_expired',
  'invalid_credentials',
  'refresh_token_not_found',
];

const AUTH_ERROR_NAMES_TO_IGNORE: Array<string> = [
  'AuthSessionMissingError',
  'AuthInvalidCredentialsError',
  'AuthRetryableFetchError',
  'AuthWeakPasswordError',
];

/**
 * We don't want to report these errors to Sentry, as they are expected to
 * happen through normal use of the platform.
 */
export function isIgnorableSupabaseAuthError(error: unknown) {
  if (!isAuthError(error)) {
    return false;
  }

  if (
    AUTH_ERROR_CODES_TO_IGNORE.includes(error.code) ||
    AUTH_ERROR_NAMES_TO_IGNORE.includes(error.name)
  ) {
    return true;
  }

  return false;
}
