// Required for TRPC
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { TrpcWebRouter } from '@lupa/server/routers/trpcWebRouter';

import {
  createTRPCProxyClient,
  createTRPCReact,
  httpBatchLink,
} from '@trpc/react-query';
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server';

import { globalSingleton } from '../singletons/globalSingleton';
import { API_ENDPOINT } from './axios';

export type TrpcRouterInputs = inferRouterInputs<TrpcWebRouter>;
export type TrpcRouterOutputs = inferRouterOutputs<TrpcWebRouter>;

export const trpc = createTRPCReact<TrpcWebRouter>();

const trpcLinks = [
  httpBatchLink({
    url: `${API_ENDPOINT}/api/trpc/web`,
    maxURLLength: 8000, // Cloudfront max is 8192, so we'll add some safety margin

    // You can pass any HTTP headers you wish here
    async headers() {
      return {
        authorization: globalSingleton.accessToken!,
      };
    },
  }),
];

export const trpcClient = trpc.createClient({
  links: trpcLinks,
});

export const trcpProxyClient = createTRPCProxyClient<TrpcWebRouter>({
  links: trpcLinks,
});
