import { useCallback, useEffect, useMemo } from 'react';

import { useAuth } from '../../hooks/use-auth';
import { randomNumberInRange } from '../../utils/randomUtils';

/**
 * This component is responsible for refreshing Supabase session tokens.
 *
 *
 * CONTEXT
 *
 * Supabase does include some token refresh functionality by default, but we
 * have repeatedly seen it not be effective. This *might* be because it only
 * runs when the window is focussed, or because we don't call getSession in many
 * places?
 *
 * Ultimately, we want to be certain that session tokens are kept up to date
 * wherever possible to avoid interruptions to the user's workflow, so we
 * decided to implement our own refresh logic.
 *
 *
 * IMPLEMENTATION
 *
 * When the session expiry time changes, we set a timeout to refresh the session
 * token refreshThresholdMs before the session expires. This timeout is cleared
 * and recreated whenever the expiry time changes.
 *
 * We chose how far out to start attempting a refresh (refreshThresholdMs) with
 * some randomness to avoid thundering herd, and also avoid multiple tabs each
 * attempting to refresh at the same time by storing the last refresh time in
 * localStorage.
 */

const ONE_MINUTE_MS = 60 * 1000;
const TOKEN_REFRESH_THRESHOLD_RANGE_MS = {
  min: 5 * ONE_MINUTE_MS,
  max: 10 * ONE_MINUTE_MS,
};

const REFRESH_RETRY_INTERVAL_MS = 3000;
const LOCALSTORAGE_LAST_REFRESH_TIME_KEY = 'lupa_auth_last_refresh_time';

function debugLog(...args: unknown[]) {
  // Commented out in prod
  console.debug('[AuthTokenRefresher]', ...args);
}

function getMillisBeforeExpiry(expiresAtTimestamp: number) {
  return expiresAtTimestamp * 1000 - Date.now();
}

export const AuthTokenRefresher = () => {
  const { session, refreshSession } = useAuth();

  /**
   * refreshThresholdMs is chosen randomly between the bounds defined in
   * TOKEN_REFRESH_THRESHOLD_RANGE_MS. We do this to avoid thundering herd
   * issues when the user has multiple tabs open.
   */
  const refreshThresholdMs = useMemo(
    () =>
      randomNumberInRange(
        TOKEN_REFRESH_THRESHOLD_RANGE_MS.min,
        TOKEN_REFRESH_THRESHOLD_RANGE_MS.max,
      ),
    [],
  );

  const maybeRefreshSession = useCallback(async () => {
    // We don't refresh if it was recently refreshed. That can happpen if the
    // user has another tab open

    const lastRefreshStr = localStorage.getItem(
      LOCALSTORAGE_LAST_REFRESH_TIME_KEY,
    );
    if (lastRefreshStr != null) {
      const lastRefreshTime = new Date(lastRefreshStr);
      const timeSinceLastRefreshMs =
        new Date().getTime() - lastRefreshTime.getTime();

      if (timeSinceLastRefreshMs <= REFRESH_RETRY_INTERVAL_MS) {
        debugLog('Skipping refresh - was refreshed recently');
        return { success: false, error: null };
      }
    }

    debugLog('Refreshing session');
    localStorage.setItem(
      LOCALSTORAGE_LAST_REFRESH_TIME_KEY,
      new Date().toISOString(),
    );

    const { error } = await refreshSession();

    return { success: error == null, error };
  }, [refreshSession]);

  useEffect(() => {
    if (session == null) {
      debugLog('No session - skipping');
      return;
    }

    const { expires_at: expiresAtTimestamp } = session;

    if (expiresAtTimestamp == null) {
      console.error(
        '[AuthTokenRefresher] No expiry time found for session - not scheduling refresh',
      );
      return;
    }

    const millisBeforeExpiry = getMillisBeforeExpiry(expiresAtTimestamp);
    const timeTilRefreshMs = Math.max(
      0,
      millisBeforeExpiry - refreshThresholdMs,
    );

    debugLog(
      `Session expires at ${expiresAtTimestamp}, will trigger refresh in ${timeTilRefreshMs}ms (targetting ${
        refreshThresholdMs / 1000 / 60
      } minutes before expiry)`,
    );

    let sessionUpdated = false;
    const timeoutId = setTimeout(async () => {
      /* eslint-disable no-constant-condition */
      /* eslint-disable no-await-in-loop */
      while (!sessionUpdated) {
        const { success, error } = await maybeRefreshSession();
        if (success) {
          break;
        }

        if (error) {
          console.error('Error refreshing session:', error);
        }

        await new Promise((resolve) => {
          setTimeout(resolve, REFRESH_RETRY_INTERVAL_MS);
        });
      }
    }, timeTilRefreshMs);

    return () => {
      sessionUpdated = true;
      clearTimeout(timeoutId);
    };
  }, [session]);

  return null;
};
