import React from 'react';

import ReactDOM from 'react-dom/client';

import { AuthProvider } from '@lupa/ui/contexts/auth/AuthProvider';
import { AuthTokenRefresher } from '@lupa/ui/contexts/auth/AuthTokenRefresher';
import '@lupa/ui/styles/globals.css';
import { initSentryClient } from '@lupa/ui/utils/instrumentClient';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

import { enGB } from 'date-fns/locale';
import { enableMapSet as immerEnableMapSet } from 'immer';
import ReactGA from 'react-ga4';

import App from './App';
import './i18n';
import { API_ENDPOINT } from './lib/axios';
import { getSupabase } from './lib/supabase';
import reportWebVitals from './reportWebVitals';
import { updateGlobalSingletonWithSession } from './singletons/globalSingleton';
import { IS_DEV } from './utils/environment-utils';

import './index.css';

if (!IS_DEV) {
  ReactGA.initialize('G-524VVQ2FTZ');

  initSentryClient({
    dsn: 'https://ceda59b4f2941344a54fa0f0bd399f03@o4507455239553024.ingest.de.sentry.io/4507455485378640',
    backendUrl: API_ENDPOINT,
    environment: IS_DEV ? 'development' : 'production',
  });
}

immerEnableMapSet();

const root = ReactDOM.createRoot(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  document.getElementById('root') as HTMLElement,
);

const AppWithProviders = () => (
  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
    <AuthProvider
      supabaseProvider={getSupabase}
      sessionUpdateCallback={updateGlobalSingletonWithSession}
    >
      <AuthTokenRefresher />
      <App />
    </AuthProvider>
  </LocalizationProvider>
);

root.render(
  IS_DEV ? (
    <React.StrictMode>
      <AppWithProviders />
    </React.StrictMode>
  ) : (
    <AppWithProviders />
  ),
);

reportWebVitals();
